.ad-break-enabled {
  border: 6px solid #EBAF15;
}

.game-main {
  .ant-steps-item-title {
    width: 120px;
    text-transform: uppercase;
    line-height: 15px !important;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 13px;
  }
  .ant-steps-vertical .ant-steps-item-content {
    min-height: 65px;
  }
}
.answers-wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
.active-game-btn:focus,
.active-game-btn:active {
  background-color: #5277FF !important;
}

.ant-tabs-nav-list{
  width: 100%;

  .ant-tabs-tab {
    flex-grow: 1;
    width: 100%;
    text-align: center;

    .ant-tabs-tab-btn {
      width: 100%;

      &:focus,
      &:active {
        font-weight: 900;
      }
    }
  }
}

.error-warning {
  color: #FF5252;
  font-weight: 500;
  .anticon {
    margin-right: 10px;
  }
}
