body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}
body.adbreak-enabled {
  border: 6px solid #EBAF15;
}
body.error-enabled {
  border: 6px solid #FF5252;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
