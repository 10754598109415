.ant-btn {
  text-transform: uppercase;
  height: 50px;
  padding: 13px 16px;

  &:not([disabled]) {
    &.warning-btn {
      border-color: #EBAF15;
      color: #EBAF15;

      &:hover,
      &:focus {
        background-color: #EBAF15;
        color: #ffffff;
        border-color: #ffffff;
      }
    }
    &.error-btn {
      border-color: #FF5B5B;
      color: #FF5B5B;

      &:hover,
      &:focus {
        background-color: #FF5B5B;
        color: #ffffff;
        border-color: #ffffff;
      }
    }
  }
}