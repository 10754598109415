.d-flex {
	display: flex;
}
.d-block {
	display: block;
}
.text-center {
	text-align: center;
}
.text-left {
	text-align: left;
}
.clear {
	clear: both;
}
.hidden {
	display: none;
}