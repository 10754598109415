.game-list {
  .add-episode {
    display: flex;
    flex-flow: row nowrap;
    text-transform: uppercase;
    font-weight: 900;
    max-width: 150px;
  
    .anticon {
      font-size: 32px;
    }
    .link-copy {
      font-size: 15px;
      white-space: normal;
      line-height: 112%;
    }
  }
  .featured-game-card {
    box-shadow: 0px 8px 14px rgba(36, 41, 59, 0.12);
    border-radius: 10px;
  }
  .ant-tabs-nav {
    margin: 0 !important;
  }
  .ant-tabs-tab {
    .ant-tabs-tab-btn {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 18px;
      color: #9C9EA6;
    }
    &.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #5277FF;
    }
  }
  .ant-tabs-content-holder {
    background: #FFFFFF;
    box-shadow: 0px 8px 14px -7px rgba(36, 41, 59, 0.12);
    border-radius: 0px 0px 10px 10px;
    padding: 20px;  
  }
}
