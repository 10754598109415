.nav-title{
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 25px;

  .side-bar-title{
    margin: 0;
    padding: 0;
  }
  .header-logo {
  padding: 15px;
  }
}
.side-bar-title {
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
}

.sider-trigger {
  border-left: 14px solid #001529;
  border-bottom: 10px solid transparent;
  border-top: 10px solid transparent;
  width: 0;
  height: 66px;
  position: absolute;
  right: -14px;
  top: 50%;
  transform: translateY(-50%);
  color: #ffffff;
  &:hover {
    cursor: pointer;
  }

  .anticon {
    position: absolute;
    top: 50%;
    font-size: 10px;
    left: 0;
    transform: translate(-100%, -50%);
  }
}