.game-card-inner {
  width: 100%;
  
  .game-card-title {
    font-weight: 700;
    font-size: 17px;
    text-transform: uppercase;
  }

  .game-card-title_icon {
    font-size: 26px;
  }
  &.game-card-featured {
    .game-thumb {
      border-radius: 4px;
      width: 150px;
    }
  }
  .episode-thumb-wrapper {
    display: flex;
    flex-flow: row wrap;
  }

  .game-card-action {
    font-weight: 900;
    font-size: 14px;
    text-transform: uppercase;
  }

  .game-card-date {
    font-weight: 500;
    font-size: 15px;
  }
  
  .game-card-countdown {
    font-weight: 900;
    font-size: 15px;
  }
  
  .start-game-btn {
    position: absolute;
    bottom: -25px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  
    img {
      vertical-align: text-bottom;
    }
  }

  &.game-card-featured {
    .game-card-episode {
      font-weight: 300;
      font-size: 33px;
    }
  }
}
