.answer {
  color: #cc0000;

  &.correct {
    background-color: #49b50c;
    color: #000000;
    .ant-card-head {
      color: #000000;
    }
  }
}

