.answer-option {
  background: #FFFFFF;
  border: 1px solid #F8F9FF;
  box-sizing: border-box;
  box-shadow: 0px 14px 14px -10px rgba(36, 41, 59, 0.12);
  border-radius: 100px;
  min-width: 200px;
  min-height: 46px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin-right: 10px;
  margin-bottom: 10px;


  &.inactive {
    box-shadow: 0px 4px 14px -9px rgba(36, 41, 59, 0.12);
  }

  .answer-indication {
    background: rgba(193, 196, 212, .15);
    box-sizing: border-box;
    border-radius: 100px;
    color: #8B92B4;
    padding: 3px 10px;
    margin-right: 7px;
    text-overflow: ellipsis;
  }
}