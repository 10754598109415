.notification {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 40px 60px;
  color: #ffffff;

  &.notification-warning {
    background-color: #EBAF15;

    #notification-banner {
      background-color: #EDC359;
    }
    .ant-btn-primary {
      color: #EBAF15;
    }
  }
  &.notification-error {
    background-color: #FF5252;

    #notification-banner {
      background-color: #FF8484;
    }
    .ant-btn-primary {
      color: #FF5252;
    }
  }

  #notification-banner {
    position: absolute;
    top: -15px;
    padding: 10px 20px;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 900;
    font-size: 15px;
    box-shadow: 0px 14px 14px -10px rgba(36, 41, 59, 0.12);
    border-radius: 4px;
  }

  .notification-icon-wrapper .anticon {
    font-size: 40px;
  }
  p {
    color: #ffffff;
    padding: 0;
    margin: 0;
    line-height: 100%;
  }
  p.notification-title {
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 10px;
  }
  p.notification-subtitle {
    font-weight: 400;
    font-size: 16px;
  }
  .ant-btn {
    font-size: 16px;
    padding: 20px 30px;
    height: auto;
    line-height: 100%;
    color: #ffffff;
    background-color: transparent;

    &.ant-btn-primary {
      background: #ffffff;
      border-color: #ffffff;
    }
  }
}